@import 'src/styles/main';

/* Reset button default styling */

button {
    padding: 12px 24px;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
}

button:hover {
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
}

button:active {
    outline: none;
}

button:focus {
    outline: 0;
}

/* END Reset button default styling */

.normal-btn {
    border-radius: 18px;
    background-color: $color-primary;
    color: $color-black;
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
}

.btn-outlined,
.btn-outlined:hover,
.btn-outlined:active,
.btn-outlined:focus {
    border-width: 2px;
    border-color: $color-primary;
    background-color: transparent;
    color: $color-primary;
}

.btn-outlined.inverted {
    color: $color-black;
    border-color: $color-black;
}
