@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'worksans';
@import 'firasans';
@import 'gothamrounded';
@import 'locomotive-scroll';

// primary font
$primary_font: 'Fira Sans';

// Font Weights
$extralight: 100;
$thin: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 700;
$black: 900;

// Colors
$color-dark: #515151;
$color-light: white;
$color-primary: white;
$color-black: #000;
$color-grey: #b5b5b5;
$color-green: #3bc849;
$color-arrow-green: #5ECD42FF;
$color-arrow-red: #FF7245FF;
$color-blue: #0476ED;

$enable-negative-margins: true;