@import 'src/styles/main';

.image-container {
    height: auto;
    width: 100%;
    
    .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}