@import 'src/styles/main';

header {
    position: relative;
    z-index: 100;
    height: 112px;
    color: $color-black;
    opacity: 0;
    border-radius: 31px;

    @include media-breakpoint-down(lg) {
        height: 90px;
    }

    @include media-breakpoint-down(md) {
        background: transparent;
        position: absolute !important;
    }
}

header .background {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: saturate(180%) blur(20px);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    border-radius: 31px;
}

header .navbar-brand {
    margin-right: 0;
}

header .navbar-brand img {
    @include media-breakpoint-down(md) {
        width: 172px;
    }
}

.main-layout nav.bottom-navbar {
    display: none;
}

.lum-logo-header {
    object-fit: contain;
}
