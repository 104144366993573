@import 'src/styles/main';

html {
    font-size: 16px;
}

body {
    font-family: $primary_font !important;
    font-size: 1rem;
    font-weight: $regular;
}

h1 {
    font-kerning: none;
    -webkit-text-rendering: optimizeSpeed;
    text-rendering: optimizeSpeed;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

main {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $color-light;
    color: $color-black;
    flex: 1;
    min-height: 100vh;
    overflow: hidden;
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-black;
}

section.dark {
    background-color: $color-black;
}

section.light {
    background-color: $color-light;
}

section.light h1,
section.light h2,
section.light p {
    color: $color-black;
}

.container {
    position: relative;
}

.scale-anim {
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.05);
    }
}

.dark-icon-wrapper {
    background-color: $color-black;
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.dark-icon-wrapper img {
    width: 100%;
}

.light-icon-wrapper {
    background-color: rgba(255, 255, 255, 0.2);
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.light-icon-wrapper img {
    width: 100%;
}

.blue-icon-wrapper {
    background-color: #2864d1;
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.blue-icon-wrapper img {
    width: 100%;
}

#loader {
    z-index: 9999;
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $color-light;
}

#loader.slow-hide {
    animation: slowHideAnim 300ms forwards linear;
}

#loader .loading-bar-container {
    width: 300px;
    margin-top: 34px;
}

@keyframes slowHideAnim {
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
        display: none;
        z-index: -9999;
    }
}
