@import 'src/styles/main';

footer {
    position: relative;
    overflow: hidden;
    .content {
        background: linear-gradient(87.22deg, rgba(242, 247, 255, 0.8) -1.84%, rgba(195, 190, 255, 0.168) 110.25%);
        backdrop-filter: blur(103.5px);
        margin-top: auto;
        padding-top: 4rem;
        padding-bottom: 3.5rem;
        padding-left: 5rem;
        padding-right: 5rem;
        color: $color-black;
        @include media-breakpoint-down(lg) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .ellipse {
        position: absolute;
        
        &.ellipse-1 {
            left: -50px;
            bottom: -100px;
        }

        &.ellipse-2 {
            right: 400px;

        }
    }
}

footer form, footer form input {
    cursor: pointer;
}

footer .form-control,
footer .form-control:focus,
footer input.form-control:-webkit-autofill,
footer input.form-control:-webkit-autofill:hover,
footer input.form-control:-webkit-autofill:focus,
footer input.form-control:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px transparent inset;
    background-color: transparent !important;
    -webkit-text-fill-color: $color-black !important;
    color: $color-black !important;
    &::placeholder {
        color: $color-black !important;
    }
}

footer .footer-link {
    margin-bottom: 0 !important;
    padding-bottom: 14px;
}

footer p.footer-link {
    font-size: 0.875rem;
}

footer .github-icon {
    filter: brightness(0);
    object-fit: contain;
}

footer .color-error {
    color: #ff7245;
}
