@import 'src/styles/main';

#use-cases {
    color: $color-black;
    padding-bottom: 140px;

    @include media-breakpoint-down(lg) {
        padding-bottom: 72px;
    }

    h1 {
        font-weight: $bold;
        font-size: 50px;
    }
}
