@import 'src/styles/main';

#lum-tools {
    padding-bottom: 140px;
    white-space: pre-line;
    
    @include media-breakpoint-down(lg) {
        padding-bottom: 72px;
    }

    h1 {
        font-weight: $bold;
        font-size: 50px;
    }

    p {
        font-size: 18px;
        line-height: 22px;
    }

    .tools-btn {
        width: fit-content;
        background-color: $color-blue;
        color: white;
        height: 100%;
        padding: 0.75rem 1.5rem;
    }

    .github-btn {
        width: fit-content;

        // Same padding as the other CTAs but minus the 2px border
        padding: calc(0.75rem - 2px) 1.5rem;
        height: min-content;

        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            filter: brightness(0);
        }
    }
}
