@import 'src/styles/main';

.progress {
    height: 5px !important;
    background-color: #E7E7E7 !important;
    overflow: visible !important;
}

.progress-bar {
    background-color: #515151 !important;
    -webkit-box-shadow: 0px 0px 10px 2px #ffffff;
    box-shadow: 0px 0px 10px 2px #ffffff;
}
